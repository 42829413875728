.root {
  width: 0;
  height: 0;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute;
  top: 0;
  left: 0;
}

.root:focus {
  width: auto;
  height: auto;
  clip: auto;
  top: 1rem;
  left: 1rem;
  z-index: 1;
}
