.root {
  text-align: left;
  align-items: center;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  width: 100%;
  position: relative;
  padding: 0 .5rem;
}

.root:not(.skeleton) {
  cursor: pointer;
}

.root:not(.skeleton)::before {
  content: "";
  background-color: var(--onBackground);
  border-radius: var(--cardRadius-1);
  opacity: 0;
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  z-index: -1;
}

.root:hover::before {
  opacity: 1;
}

.selected {
  outline: 0;
  box-shadow: 0 0 0 2px #009e77, 0 0 0 6px rgb(40 180 130 / 20%);
  box-shadow: var(--focus);
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  padding-right: 1rem;
}

.content {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.title {
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.skeleton .body > div {
  background-color: var(--onBackground);
  height: 1em;
  width: 40%;
}

.skeleton .body > div:last-of-type {
  width: 80%;
}

.thumbnail {
  color: var(--backgound);
  background-color: var(--background);
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-1);
  margin-right: var(--spacingS);
  width: 3rem;
  height: 4.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.dropdownItem {
  padding: var(--spacingS);
  width: 100%;
  text-align: left;
  border-bottom: 1px solid var(--stroke-1);
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: var(--onBackground);
}

.dropdown {
  display: none;
}

.dropdown>div>button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  border-color: var(--buttonStroke);
  color: var(--inputText);
  background-color: var(--background);
}

.root:hover .dropdown {
  display: block;
}
