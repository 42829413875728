.size_13 {
  font-size: 0.8125rem;
}
.size_14 {
  font-size: 0.875rem;
}
.size_16 {
  font-size: 1rem;
}
.size_18 {
  font-size: 1.125rem;
}
.size_22 {
  font-size: 1.375rem;
}
.size_28 {
  font-size: 1.75rem;
}
.size_32 {
  font-size: 2rem;
}
.bold {
  font-weight: 500;
}

.muted {
  color: var(--text-2);
}

.dim {
  color: var(--text-3);
}

.smallcaps {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-variant-caps: all-small-caps;
}

.underline {
  text-decoration: underline;
}
