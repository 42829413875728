.checkIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--primary);
  margin-left: 0.25rem;
}

.lockIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--text-3);
  margin-left: 0.25rem;
}
