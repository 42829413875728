.mintGreenLight {
  border-left: 4px solid #d4f0eb;
}

.mintGreen {
  border-left: 4px solid #a5e1d2;
}

.summerGreen {
  border-left: 4px solid #28b482;
}

.seaGreen {
  border-left: 4px solid #007272;
}

.emeraldGreen {
  border-left: 4px solid #14555a;
}

.oceanGreen {
  border-left: 4px solid #00343e;
}