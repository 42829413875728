.logo {
  color: var(--primary);
  width: 100%;
  max-width: 11rem;
  height: auto;
}

.small {
  max-width: 8rem;
}

@media (prefers-color-scheme: dark) {
  .logo {
    color: var(--onSecondary);
  }
}
