.styles-module_root__-hmCg {
  text-align: center;
}

.styles-module_loading__88JFg {
  display: inline-flex;
  transform: translateY(-0.5em);
}

.styles-module_large__8qVBU {
  font-size: 150%;
}

.styles-module_standard__D3Q-D {
  color: var(--secondary);
}
.styles-module_action__N7Ly1 {
  color: var(--primary);
}
.styles-module_outline__hnYRV {
  color: var(--onPrimary);
}

.styles-module_dot__VAma5 {
  border-radius: 100%;
  width: 0.5em;
  height: 0.5em;
  background-color: currentColor;
  animation-name: styles-module_bounce__vcmex;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.styles-module_dot__VAma5:nth-of-type(2) {
  animation-delay: 0.25s;
}
.styles-module_dot__VAma5:nth-of-type(3) {
  animation-delay: 0.5s;
}
.styles-module_dot__VAma5:not(:first-of-type) {
  margin-left: 0.5em;
}

@keyframes styles-module_bounce__vcmex {
  to {
    transform: translateY(1em);
  }
}

.styles-module_button__cqjs8 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  border-radius: 3.1875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 2.5rem;
  display: inline-flex;
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 0.25rem;
  grid-gap: 0.25rem;
}

.styles-module_button__cqjs8:not(:disabled):hover {
  cursor: pointer;
}

.styles-module_button__cqjs8:disabled {
  color: var(--text-3);
  background-color: var(--stroke-1);
  border-color: var(--stroke-2);
}

.styles-module_button__cqjs8 > svg {
  width: 1rem;
  height: 1rem;
  transform: translateY(1px);
}

.styles-module_size_small__81JJg {
  font-size: 0.875rem;
}

.styles-module_size_large__N-gv7 {
  font-size: 1.125rem;
  display: grid;
  justify-content: center;
  width: 100%;
}

.styles-module_size_medium__uF3pk {
  font-size: 1.125rem;
}

.styles-module_theme_standard__ur-jb {
  color: var(--onSecondary);
  background-color: var(--secondary);
}

.styles-module_theme_standard__ur-jb:hover {
  background-color: var(--secondaryHover);
}

.styles-module_theme_action__b5wwO {
  color: var(--onPrimary);
  background-color: var(--primary);
}

.styles-module_theme_action__b5wwO:hover {
  background-color: var(--primaryHover);
}

.styles-module_theme_outline__kXaEU {
  color: var(--inputText);
  background-color: var(--background);
  border: 1px solid var(--buttonStroke);
}

.styles-module_theme_outline__kXaEU:hover {
  background-color: var(--onBackground);
}

/* FIXED SIZES
.size_small {
  font-size: var(--fontXS); 
  padding-left: var(--spacingS); 
  padding-right: var(--spacingS);
  height: 2rem; 
}

.size_default {
  padding-left: 1.25rem; 
  padding-right: 1.25rem;
  height: 2.5rem; 
}

.size_large {
  font-size: var(--fontM); 
  padding-left: var(--spacingM); 
  padding-right: var(--spacingM);
  height: 3rem; 
}
 */

.styles-module_spacing_xxs__X8zPZ {
  margin-bottom: var(--spacingXXS);
}

.styles-module_spacing_xs__jVKTQ {
  margin-bottom: var(--spacingXS);
}

.styles-module_spacing_s__Sil90 {
  margin-bottom: var(--spacingS);
}

.styles-module_spacing_m__WiZoe {
  margin-bottom: var(--spacingM);
}

.styles-module_spacing_l__pjTR4 {
  margin-bottom: var(--spacingL);
}

.styles-module_spacing_xl__FK3l4 {
  margin-bottom: var(--spacingXL);
}

.styles-module_spacing_xxl__sTsR6 {
  margin-bottom: var(--spacingXL);
}

.styles-module_responsive__7ESV8.styles-module_spacing_xs__jVKTQ {
  margin-bottom: 0.625rem;
}
.styles-module_responsive__7ESV8.styles-module_spacing_s__Sil90 {
  margin-bottom: var(--spacingXS);
}
.styles-module_responsive__7ESV8.styles-module_spacing_m__WiZoe {
  margin-bottom: 1.25rem;
}
.styles-module_responsive__7ESV8.styles-module_spacing_l__pjTR4 {
  margin-bottom: var(--spacingM);
}
.styles-module_responsive__7ESV8.styles-module_spacing_xl__FK3l4 {
  margin-bottom: 2.5rem;
}
.styles-module_responsive__7ESV8.styles-module_spacing_xxl__sTsR6 {
  margin-bottom: var(--spacingXL);
}

@media (min-width: 375px) {
  .styles-module_responsive__7ESV8.styles-module_spacing_xs__jVKTQ {
    margin-bottom: var(--spacingXS);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_s__Sil90 {
    margin-bottom: var(--spacingS);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_m__WiZoe {
    margin-bottom: var(--spacingM);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_l__pjTR4 {
    margin-bottom: var(--spacingL);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xl__FK3l4 {
    margin-bottom: var(--spacingXL);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xxl__sTsR6 {
    margin-bottom: var(--spacingXXL);
  }
}

@media (min-width: 768px) {
  .styles-module_responsive__7ESV8.styles-module_spacing_xxs__X8zPZ {
    margin-bottom: var(--spacingXS);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xs__jVKTQ {
    margin-bottom: var(--spacingS);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_s__Sil90 {
    margin-bottom: var(--spacingM);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_m__WiZoe {
    margin-bottom: var(--spacingL);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_l__pjTR4 {
    margin-bottom: var(--spacingXL);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xl__FK3l4 {
    margin-bottom: 4.5rem;
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xxl__sTsR6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1440px) {
  .styles-module_responsive__7ESV8.styles-module_spacing_m__WiZoe {
    margin-bottom: 2.5rem;
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_l__pjTR4 {
    margin-bottom: var(--spacingXXL);
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xl__FK3l4 {
    margin-bottom: 5.5rem;
  }
  .styles-module_responsive__7ESV8.styles-module_spacing_xxl__sTsR6 {
    margin-bottom: 9rem;
  }
}

.styles-module_size_13__19IrG {
  font-size: 0.8125rem;
}
.styles-module_size_14__5kj4T {
  font-size: 0.875rem;
}
.styles-module_size_16__ii3vk {
  font-size: 1rem;
}
.styles-module_size_18__kMgi4 {
  font-size: 1.125rem;
}
.styles-module_size_22__jroOd {
  font-size: 1.375rem;
}
.styles-module_size_28__cbwMa {
  font-size: 1.75rem;
}
.styles-module_size_32__U3Gsv {
  font-size: 2rem;
}
.styles-module_bold__9zJpR {
  font-weight: 500;
}

.styles-module_muted__89I96 {
  color: var(--text-2);
}

.styles-module_dim__D6UBf {
  color: var(--text-3);
}

.styles-module_smallcaps__xq5fV {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-variant-caps: all-small-caps;
}

.styles-module_underline__HMmvc {
  text-decoration: underline;
}

.styles-module_label__CcmAz {
  display: block;
}
.styles-module_root__Kapdf {
  display: flex;
  justify-content: space-between;
}

.styles-module_root__vW4UK {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles-module_fieldset__wd1aH {
  width: 100%;
}

.styles-module_fieldset__wd1aH[data-prefix]:before {
  content: attr(data-prefix);
  color: var(--text-3);
}

@media (min-width: 56.25em) {
  .styles-module_expanded__VKZzx {
    position: relative;
  }

  .styles-module_expanded__VKZzx[data-prefix]:before {
    padding-right: 0.5rem;
    position: absolute;
    top: 0;
    right: 100%;
  }
}

.styles-module_root__TMZKV {
  border-radius: var(--cardRadius-1);
  padding: 0.75rem;
  display: inline-flex;
  align-items: center;
}

.styles-module_root__TMZKV a {
  text-decoration: underline;
}
.styles-module_root__TMZKV a:hover {
  text-decoration: none;
}

.styles-module_icon__U-6Pm {
  color: var(--text-1);
  margin-right: 0.5rem;
  margin-top: 0.125rem;
  flex-shrink: 0;
}

.styles-module_default__SR3Pj {
  background-color: var(--background);
  box-shadow: 0 0 0 1px var(--stroke-1);
}

.styles-module_defaultElevated__z8nX- {
  box-shadow: 0 0 0 1px var(--stroke-1), 0px 2px 8px var(--stroke-1);
}

.styles-module_error__Qlhh0 {
  background-color: var(--error-2);
}

.styles-module_errorElevated__rDKSj {
  box-shadow: 0 0 0 1px var(--error-1), 0px 2px 8px var(--stroke-1);
}

.styles-module_error__Qlhh0 .styles-module_icon__U-6Pm,
.styles-module_errorElevated__rDKSj .styles-module_icon__U-6Pm {
  color: var(--error-1);
}

.styles-module_success__GYpnr {
  background-color: var(--confirm-2);
}

.styles-module_warning__UFJ56 {
  background-color: var(--warning-1);
}

.styles-module_warningElevated__LKe4V {
  box-shadow: 0 0 0 1px var(--warning-1), 0px 2px 8px var(--stroke-1);
}

.styles-module_warning__UFJ56 .styles-module_icon__U-6Pm,
.styles-module_warningElevated__LKe4V .styles-module_icon__U-6Pm {
  color: var(--onConfirm-2);
}

.styles-module_successElevated__xq-mC {
  box-shadow: 0 0 0 1px var(--confirm-1), 0px 2px 8px var(--stroke-1);
}

.styles-module_success__GYpnr .styles-module_icon__U-6Pm,
.styles-module_successElevated__xq-mC .styles-module_icon__U-6Pm {
  color: var(--confirm-1);
}

@media (prefers-color-scheme: dark) {
  .styles-module_text__zaDdU {
    color: var(--error-1);
  }
}

.styles-module_title__hN0Fj {
  color: var(--error-1);
  display: flex;
  align-items: center;
}

.styles-module_icon__Uxqau {
  margin-right: var(--spacingXXS);
}

.styles-module_list__yUbXV {
  list-style: disc inside;
}

.styles-module_contentAfterSubmit__U-SeZ {
  margin-top: 4.5rem;
}

.styles-module_base__uLL3N {
  font-size: 1.125rem;
  color: var(--inputText);
  background-color: var(--background);
  box-shadow: 0px 0px 0px 1px var(--background),
    0px 0px 0px 2px var(--inputStroke);
  border-radius: var(--inputRadius);
  border: none;
  padding: 0.75rem 1rem;
  margin-left: 2px;
  width: calc(100% - 4px);
}
.styles-module_input__rRSE3 {
}
.styles-module_textarea__-K8uJ {
  min-height: 6.3125rem;
}
.styles-module_base__uLL3N:focus {
  box-shadow: 0px 0px 0px 2px var(--text-1);
  outline: none;
}
input.styles-module_base__uLL3N:-moz-read-only {
  background-color: var(--stroke-1);
  box-shadow: 0px 0px 0px 1px var(--stroke-1), 0px 0px 0px 2px var(--stroke-1);
}
.styles-module_base__uLL3N:disabled,
input.styles-module_base__uLL3N:read-only {
  background-color: var(--stroke-1);
  box-shadow: 0px 0px 0px 1px var(--stroke-1), 0px 0px 0px 2px var(--stroke-1);
}
.styles-module_base__uLL3N:disabled {
  color: var(--text-3);
  -webkit-text-fill-color: var(--text-3);
}
.styles-module_base__uLL3N::-moz-placeholder {
  color: var(--inputTextPlaceholder);
}
.styles-module_base__uLL3N::placeholder {
  color: var(--inputTextPlaceholder);
}
.styles-module_base__uLL3N:-ms-input-placeholder {
  color: var(--inputTextPlaceholder) !important;
}
.styles-module_base__uLL3N::-ms-input-placeholder {
  color: var(--inputTextPlaceholder);
}
.styles-module_base__uLL3N.styles-module_error__8H-1m {
  box-shadow: 0px 0px 0px 2px var(--error-1);
}

.styles-module_inputContainer__Ab-yT {
  position: relative;
}

.styles-module_inputContainer__Ab-yT .styles-module_base__uLL3N {
  padding-left: 2.625rem;
}

/* The icon */
.styles-module_inputContainer__Ab-yT>*:first-child {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.styles-module_switch__tg-YG {
  color: var(--inputText);
  display: flex;
}

.styles-module_large__bTlWz {
  height: 3rem;
  max-width: 16rem;
}

.styles-module_small__IjXC4 {
  height: 2.375rem;
  max-width: 16.25rem;
}

.styles-module_item__A4Lm4 {
  flex-basis: 50%;
  flex-grow: 1;
  position: relative;
}

.styles-module_label__39s2h {
  background-color: var(--background);
  border: 1px solid var(--inputStroke);
  border-left-width: 0;
  border-right-width: 0;
  height: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 0.125rem;
  align-items: center;
  position: relative;
}

.styles-module_small__IjXC4 .styles-module_label__39s2h {
  font-weight: 500;
  font-size: 0.875rem;
}
.styles-module_large__bTlWz .styles-module_label__39s2h {
  font-size: 1.125rem;
}

.styles-module_error__H-m6U .styles-module_label__39s2h {
  border-color: var(--error-1);
  box-shadow: 0px 0px 0px 1px var(--error-1);
}

.styles-module_default__Vc85x .styles-module_item__A4Lm4:not(:first-of-type) .styles-module_label__39s2h {
  border-left: 1px solid var(--inputStroke);
}

.styles-module_switch__tg-YG:not(.styles-module_default__Vc85x) .styles-module_item__A4Lm4:not(:first-of-type):not(.styles-module_selected__GYHAM) .styles-module_label__39s2h {
  border-left: 1px solid var(--inputStroke);
}

.styles-module_item__A4Lm4:first-of-type .styles-module_label__39s2h {
  border-top-left-radius: var(--inputRadius);
  border-bottom-left-radius: var(--inputRadius);
  border-left-width: 1px;
}
.styles-module_item__A4Lm4:last-of-type .styles-module_label__39s2h {
  border-top-right-radius: var(--inputRadius);
  border-bottom-right-radius: var(--inputRadius);
  border-right-width: 1px;
}

.styles-module_input__3SVnu {
  top: 0;
  left: 0;
}

.styles-module_input__3SVnu:checked ~ .styles-module_label__39s2h {
  color: var(--background);
  background-color: var(--inputText);
  border-color: var(--inputText);
}

.styles-module_input__3SVnu:focus ~ .styles-module_label__39s2h {
  border-radius: var(--inputRadius);
  box-shadow: var(--focus);
  z-index: 1;
}
.styles-module_disabled__Kpsho {
  color: var(--text-3);
}
.styles-module_disabled__Kpsho .styles-module_label__39s2h,
.styles-module_readOnly__kJNq6 .styles-module_label__39s2h {
  background-color: var(--stroke-1);
  border-color: var(--stroke-2);
}
.styles-module_disabled__Kpsho .styles-module_item__A4Lm4:not(:first-of-type) .styles-module_label__39s2h,
.styles-module_readOnly__kJNq6 .styles-module_item__A4Lm4:not(:first-of-type) .styles-module_label__39s2h {
  border-left: 1px solid var(--stroke-2);
}

.styles-module_container__eZWuM {
  position: relative;
}

.styles-module_root__Tf-t8 {
  display: flex;
  align-items: center;
}

/*
  Indicator styling for radio and checkbox
*/
.styles-module_indicator__9Dk3z {
  color: var(--background);
  background: var(--background);
  border: 1px solid var(--inputStroke);
  margin-right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.styles-module_indicator_radio__a5sT- {
  border-radius: 100%;
}

.styles-module_indicator_radio__a5sT-::before {
  content: "";
  border-radius: 100%;
  background-color: currentColor;
  width: calc(1.5rem / 2);
  height: calc(1.5rem / 2);
}

.styles-module_indicator_checkbox__jghFy {
  border-radius: var(--cardRadius-1);
}

.styles-module_input__8swJa {
  top: 0;
  left: 0;
}

.styles-module_input__8swJa:focus + .styles-module_root__Tf-t8:not(.styles-module_large__IKgEa) .styles-module_indicator__9Dk3z {
  box-shadow: var(--focus);
}

.styles-module_input__8swJa:checked + .styles-module_root__Tf-t8 .styles-module_indicator__9Dk3z {
  color: var(--inputText);
  border-color: var(--inputText);
}

.styles-module_input__8swJa:checked + .styles-module_root__Tf-t8 .styles-module_indicator_checkbox__jghFy {
  color: var(--background);
  background-color: var(--inputText);
}

.styles-module_input__8swJa:disabled:not(:checked) + .styles-module_root__Tf-t8 .styles-module_indicator__9Dk3z {
  color: var(--stroke-2);
}

.styles-module_input__8swJa:disabled + .styles-module_root__Tf-t8 .styles-module_indicator__9Dk3z {
  color: var(--inputText);
  background-color: var(--stroke-2);
  border: 0;
}

/*
  Large modifier
*/
.styles-module_large__IKgEa {
  border: 1px solid var(--inputStroke);
  border-radius: var(--cardRadius-2);
  padding: 1rem;
}

.styles-module_input__8swJa:focus + .styles-module_large__IKgEa {
  box-shadow: var(--focus);
}

.styles-module_input__8swJa:checked + .styles-module_large__IKgEa {
  color: var(--background);
  background-color: var(--inputText);
  border-color: var(--inputText);
}

.styles-module_input__8swJa:disabled + .styles-module_large__IKgEa {
  background-color: var(--stroke-1);
  border-color: var(--stroke-2);
}

.styles-module_input__8swJa:checked:not(:disabled) + .styles-module_large__IKgEa .styles-module_indicator_checkbox__jghFy {
  color: var(--inputText);
  background-color: var(--background);
}

/*
  Error state
*/

.styles-module_error__cpsiN .styles-module_indicator__9Dk3z {
  border-color: var(--error-1);
  box-shadow: 0px 0px 0px 1px var(--error-1);
}

.styles-module_root__gJDdX {
  display: flex;
}

.styles-module_input__IRQcX {
  text-align: center;
  padding: 0.75rem !important;
  width: 2.5rem;
}

.styles-module_input__IRQcX:not(:first-of-type) {
  margin-left: 0.5rem;
}

.styles-module_badge__3gv2- {
  --accentYellow: #fff5e2;
  --accentYellow-text: #534937;
  border-radius: var(--cardRadius-1);
  padding: 0.1875rem 0.375rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  flex-shrink: 0;
}

.styles-module_circle__FwZF- {
  color: var(--background);
  background-color: var(--confirm-1);
  border-radius: 6.25rem;
  padding: 0.4rem;
  height: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.styles-module_outline__8R8Vj {
  color: var(--inputText);
  border: 1px solid var(--stroke-1);
  background-color: var(--background);
}

.styles-module_default__a-OWY {
  color: var(--background);
  background-color: var(--text-2);
  border-color: var(--text-2);
}

.styles-module_muted__4gTen {
  color: #fff;
  background-color: var(--text-3);
  border-color: var(--text-3);
}
.styles-module_success-light__TkG9Q {
  color: var(--confirm-1);
  background-color: var(--confirm-2);
  border-color: var(--confirm-2);
}
.styles-module_warning__2NtQL {
  color: var(--accentYellow-text);
  background-color: var(--accentYellow);
  border-color: var(--accentYellow);
}
.styles-module_success__YpgNr {
  color: #fff;
  background-color: var(--confirm-1);
  border-color: var(--confirm-1);
}
.styles-module_error__CRbWT {
  color: var(--onSecondary);
  background-color: var(--error-1);
  border-color: var(--error-1);
}

.styles-module_default__Uw7DU {
  --padding: 0.5rem;
  --iconSize: 2rem;
}

.styles-module_small__pzRKI {
  --padding: 0.375rem;
  --iconSize: 1.25rem;
}

.styles-module_link__c7uqz {
  color: var(--inputText);
  border-radius: 6.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.styles-module_link__c7uqz:disabled .styles-module_icon__fZytn {
  color: var(--text-3);
  background-color: var(--stroke-1);
  border-color: var(--stroke-2);
}

.styles-module_link__c7uqz:not(.styles-module_hiddenChildren__eDT8P):not(.styles-module_reverse__Rrwcj) {
  padding-left: var(--padding);
}

.styles-module_reverse__Rrwcj {
  flex-direction: row-reverse;
}

.styles-module_reverse__Rrwcj:not(.styles-module_hiddenChildren__eDT8P) {
  padding-right: var(--padding);
}

.styles-module_icon__fZytn:not(.styles-module_none__g7Rtz) {
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
  width: var(--iconSize);
  height: var(--iconSize);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
}

.styles-module_action__0jmHE {
  color: var(--onSecondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.styles-module_outline__ERnhm {
  color: var(--inputText);
  background-color: var(--background);
  border-color: var(--buttonStroke);
}

.styles-module_none__g7Rtz {
  transform: translateY(2px);
}

.styles-module_link__c7uqz:not(.styles-module_hiddenChildren__eDT8P):not(.styles-module_reverse__Rrwcj) .styles-module_icon__fZytn {
  margin-left: var(--padding);
}
.styles-module_reverse__Rrwcj:not(.styles-module_hiddenChildren__eDT8P) .styles-module_icon__fZytn {
  margin-right: var(--padding);
}

.styles-module_root__7vftH {
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}

.styles-module_root__7vftH:not(.styles-module_skeleton__sgbPj) {
  cursor: pointer;
}

.styles-module_root__7vftH:not(.styles-module_skeleton__sgbPj)::before {
  content: "";
  background-color: var(--onBackground);
  border-radius: var(--cardRadius-1);
  opacity: 0;
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  z-index: -1;
}

.styles-module_root__7vftH:hover::before {
  opacity: 1;
}

.styles-module_body__-6z5- {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.styles-module_title__voB7A {
  margin-bottom: 0;
}

.styles-module_separatorDot__dAs32 {
  background-color: var(--stroke-2);
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
}

.styles-module_documentInfo__Jff-Q {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.styles-module_bankIdIcon__9c5XG {
  background-color: #EFEEF3;
  border-color: #EFEEF3;
}

.styles-module_thumbselect__BvrLD {
  margin-left: var(--spacingS);
  padding: 1.5rem;
  width: 3rem;
  height: 4.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.styles-module_thumbnail__dQNf9 {
  color: var(--text-3);
  background-color: var(--background);
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-1);
  margin-right: var(--spacingS);
  width: 3rem;
  height: 4.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.styles-module_new__i6OZR .styles-module_thumbnail__dQNf9 {
  border-color: var(--primary);
}

.styles-module_new__i6OZR .styles-module_title__voB7A {
  color: var(--primaryHover);
}

.styles-module_title__voB7A >* {
  display: flex;
  gap: 8px;
  margin: 0;
}

.styles-module_preview__ulwC0 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.styles-module_skeleton__sgbPj .styles-module_thumbnail__dQNf9 {
  border: 0;
  background-color: var(--onBackground);
}

.styles-module_skeleton__sgbPj .styles-module_body__-6z5- > div {
  background-color: var(--onBackground);
  height: 1em;
  width: 80%;
}

.styles-module_skeleton__sgbPj .styles-module_body__-6z5- > div:last-of-type {
  width: 20%;
}

.styles-module_remove__Y43G0 {
  position: absolute;
  top: 0;
  right: 0;
}

.styles-module_badges__-BRkn {
  display: flex;
  gap: 0.5rem;
}

.styles-module_root__sphOE {
  text-align: center;
  background-color: var(--onBackgroundActive);
  border-radius: var(--cardRadius-2);
  padding: 1.5rem 2rem;
}

.styles-module_fileName__tFiXC {
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles-module_temp__iIuns {
  background-color: var(--background);
  border: 1px solid var(--stroke-2);
  border-radius: var(--cardRadius-2);
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles-module_base__uLL3N {
  font-size: 1.125rem;
  color: var(--inputText);
  background-color: var(--background);
  box-shadow: 0px 0px 0px 1px var(--background),
    0px 0px 0px 2px var(--inputStroke);
  border-radius: var(--inputRadius);
  border: none;
  padding: 0.75rem 1rem;
  margin-left: 2px;
  width: calc(100% - 4px);
}.styles-module_input__rRSE3 {
}.styles-module_textarea__-K8uJ {
  min-height: 6.3125rem;
}.styles-module_base__uLL3N:focus {
  box-shadow: 0px 0px 0px 2px var(--text-1);
  outline: none;
}input.styles-module_base__uLL3N:-moz-read-only {
  background-color: var(--stroke-1);
  box-shadow: 0px 0px 0px 1px var(--stroke-1), 0px 0px 0px 2px var(--stroke-1);
}.styles-module_base__uLL3N:disabled,
input.styles-module_base__uLL3N:read-only {
  background-color: var(--stroke-1);
  box-shadow: 0px 0px 0px 1px var(--stroke-1), 0px 0px 0px 2px var(--stroke-1);
}.styles-module_base__uLL3N:disabled {
  color: var(--text-3);
  -webkit-text-fill-color: var(--text-3);
}.styles-module_base__uLL3N::-moz-placeholder {
  color: var(--inputTextPlaceholder);
}.styles-module_base__uLL3N::placeholder {
  color: var(--inputTextPlaceholder);
}.styles-module_base__uLL3N:-ms-input-placeholder {
  color: var(--inputTextPlaceholder) !important;
}.styles-module_base__uLL3N::-ms-input-placeholder {
  color: var(--inputTextPlaceholder);
}.styles-module_base__uLL3N.styles-module_error__8H-1m {
  box-shadow: 0px 0px 0px 2px var(--error-1);
}.styles-module_inputContainer__Ab-yT {
  position: relative;
}.styles-module_inputContainer__Ab-yT .styles-module_base__uLL3N {
  padding-left: 2.625rem;
}/* The icon */.styles-module_inputContainer__Ab-yT>*:first-child {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}
.styles-module_option__kL4S4 {
  padding: var(--spacingXS) var(--spacingS);
  display: flex;
  align-items: center;
  cursor: default;
}
.styles-module_option__kL4S4:not(:last-of-type) {
  border-bottom: 1px solid var(--stroke-1);
}
.styles-module_option__kL4S4:hover {
  background-color: var(--onBackground);
}
.styles-module_icon__zQb8B {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
}
.styles-module_select__xhQAz {
  color: var(--inputText);
  position: relative;
}

.styles-module_select__xhQAz:focus-within .styles-module_input__LESFN {
  box-shadow: 0px 0px 0px 2px var(--text-1);
}

.styles-module_select__xhQAz:focus-within input:focus {
  box-shadow: none;
}

.styles-module_select__xhQAz .select__menu-list {
  background-color: var(--background);
  border: 1px solid var(--stroke-2);
  box-shadow: 0px 2px 8px rgba(0, 51, 61, 0.1);
  border-radius: var(--cardRadius-2);
  margin-top: var(--spacingXXS);
  max-height: 300px;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 100%;
  left: 0;
}

.styles-module_input__LESFN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.styles-module_select__xhQAz .select__value-container--is-multi {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.styles-module_select__xhQAz .select__multi-value__label {
  display: flex;
  align-items: center;
}
.styles-module_select__xhQAz .select__multi-value {
  background-color: var(--confirm-2);
  border-radius: var(--cardRadius-1);
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
}
.styles-module_select__xhQAz .select__multi-value__remove,
.styles-module_select__xhQAz .select__dropdown-indicator {
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
}
.styles-module_remove__-KLu1,
.styles-module_chevron__ie4Zw {
  vertical-align: top;
}
.styles-module_select__xhQAz .select__placeholder {
  color: var(--inputTextPlaceholder);
  position: absolute;
  top: 50%;
  left: calc(0.75rem + 4px);
  transform: translateY(-50%);
}
.styles-module_select__xhQAz .select__indicator-separator {
  display: none;
}
.styles-module_select__xhQAz .select__single-value {
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.styles-module_title__olPK3 {
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.styles-module_root__RGhaC {
  /* composes: elevation1 from "@samsolgt/components/elevation.css"; */
  background-color: var(--background);
  border-radius: var(--cardRadius-1);
  box-shadow: 0 0 0 1px var(--stroke-1), 0px 1px 4px var(--stroke-2);
}

.styles-module_cardSection__5ChtW {
  padding: var(--spacingM);
}

.styles-module_cardSection__5ChtW:not(:last-child) {
  border-bottom: 1px solid var(--stroke-1);
}

.styles-module_title__CxV7Q {
  margin-bottom: 1rem;
}

.styles-module_collapsibleTitle__Tijds {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles-module_collapsible__vZkwG .styles-module_cardSection__5ChtW:focus-within {
  box-shadow: var(--focus);
}

.styles-module_collapsible__vZkwG .styles-module_cardSection__5ChtW:focus-within .styles-module_collapsibleTitle__Tijds:focus {
  box-shadow: none;
}

.styles-module_collapsibleTitle__Tijds.styles-module_open__6juvN {
  margin-bottom: 1rem;
}

.styles-module_root__-ALla {
  position: relative;
}

.styles-module_tooltip__mnnfp {
  background-color: var(--inputStrokeFocus);
  color: var(--background);
  border-radius: var(--cardRadius-1);
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

.styles-module_tooltip__mnnfp:not(:focus):hover {
  background-color: var(--inputStroke);
}

.styles-module_body__00pOC {
  /* composes: elevation2 from "@samsolgt/components/elevation.css"; */
  background-color: var(--background);
  border-radius: var(--cardRadius-1);
  box-shadow: 0 0 0 1px var(--stroke-2), 0px 2px 8px var(--stroke-1);

  width: 15rem;
  padding: 0.75rem 0.875rem;
  margin-top: 0.5rem;
  position: absolute;
  top: 1.5rem;
  right: 0;
  z-index: 10;
}

.styles-module_root__rupK2 {
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-width: 0;
}

.styles-module_body__b6XBO {
  margin-left: var(--spacingXXS);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.styles-module_sub-title__vW9as {
  color: var(--text-3);
}

.styles-module_root__rupK2:not(.styles-module_inline__fNiTR) .styles-module_sub-title__vW9as {
  display: block;
}

.styles-module_loading__3YQaU {
  height: 0;
  width: 0;
  position: absolute;
}

.styles-module_frame__FZzWd {
  width: 100%;
  height: 400px;
}

.styles-module_fillSpace__m6t-H {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.styles-module_placeholder__K6mc4 {
  color: var(--onSecondary);
  background-color: var(--secondary);
  border-radius: var(--cardRadius-1);
  height: 20rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.styles-module_placeholder__K6mc4 p {
  text-align: center;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.styles-module_placeholder__K6mc4 p:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

@media (max-width: 351px) {
  .styles-module_frame__FZzWd {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
}

.styles-module_header__hjimv {
  border-bottom: 1px solid var(--stroke-1);
  margin-bottom: 1.5rem;
  display: flex;
}

.styles-module_toggle__tcrcP:not(:first-of-type) {
  margin-left: 1.5rem;
}

.styles-module_label__FwtHA {
  padding-bottom: 0.375rem;
  cursor: pointer;
  display: block;
}
.styles-module_input__2hTNi:checked ~ .styles-module_label__FwtHA {
  font-weight: 500;
  box-shadow: 0 1px 0 var(--primary);
  border-bottom: 1px solid var(--primary);
}

.styles-module_root__nqiUv {
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-2);
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  overflow: hidden;
}

.styles-module_title__c9gqq {
  font-size: 22px;
  display: block;
}

.styles-module_media__-wcv6 {
  color: var(--text-3);
  background-color: var(--onBackground);
  width: 6.25rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.styles-module_media__-wcv6 img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.styles-module_icon__94bPF {
  width: 2rem;
  height: 2rem;
}

.styles-module_body__vPLz8 {
  padding: 0.5rem 1rem;
  flex-grow: 1;
}
.styles-module_body__vPLz8 > * {
  margin-bottom: 0.25rem;
}

.styles-module_price__pnL5r {
  font-size: 18px;
}

.styles-module_sold__auj7v {
  color: var(--primary);
}

@media (min-width: 40.625em) {
  .styles-module_title__c9gqq {
    font-size: 28px;
  }
  .styles-module_price__pnL5r {
    font-size: 22px;
  }
  .styles-module_media__-wcv6 {
    width: 9.6875rem;
  }
  .styles-module_body__vPLz8 {
    padding: 1rem 1.5rem;
  }
  .styles-module_body__vPLz8 > * {
    margin-bottom: 0.5rem;
  }
}

.styles-module_root__uNeEQ {
  border-bottom: 1px solid var(--stroke-1);
  padding-bottom: 1rem;
  margin-left: 2rem;
  position: relative;
}

.styles-module_status__SfStK {
  color: var(--background);
  border: 1px solid var(--inputStroke);
  border-radius: var(--cardRadius-1);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  left: -2rem;
}

.styles-module_completed__H0xI9 .styles-module_status__SfStK {
  border-color: var(--text-3);
  background-color: var(--text-3);
}

.styles-module_completed__H0xI9 .styles-module_title__14DxV {
  text-decoration: line-through;
}

.styles-module_badge__clVgx {
  margin-top: 0.5rem;
  flex-shrink: 0;
}

.styles-module_linkIcon__OOVsa {
  position: absolute;
  top: 0.25rem;
  right: 0;
}

@media (min-width: 32em) {
  .styles-module_root__uNeEQ {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .styles-module_badge__clVgx {
    margin-top: 0;
    padding-right: 1.625rem;
  }
}

.styles-module_root__L7V1n {
  color: var(--onPrimary);
  background-color: var(--primary);
  border-radius: 100%;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  position: relative;
  box-sizing: content-box;
}

.styles-module_mini__QdOA1 {
  --borderWidth: 1px;
  font-size: 1.25em;
}
.styles-module_small__yJhnS {
  font-size: 2rem;
}
.styles-module_large__AR8ee {
  font-size: 3rem;
}

.styles-module_icon__T56ag {
  height: 62.5%;
  width: 62.5%;
}

.styles-module_online__95uih {
  background: var(--confirm-1);
  border: var(--borderWidth, 2px) solid
    var(--avatarBorderColor, var(--background));
  border-radius: 50%;
  width: 0.25em;
  height: 0.25em;
  position: absolute;
  top: -0.09375em;
  left: -0.09375em;
}

.styles-module_image__-O8SV {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: top;
}

.styles-module_group__sM8fQ {
  display: inline-block;
  flex-shrink: 0;
}

.styles-module_group_item__M-uY- {
  vertical-align: top;
  border: 1px solid var(--avatarBorderColor, var(--background));
}

.styles-module_group_item_small__LLTZH {
  border-width: 2px;
}

.styles-module_group_item__M-uY-:first-of-type {
  border-left-width: 0;
}
.styles-module_group_item__M-uY-:last-of-type {
  border-right-width: 0;
}

.styles-module_group_item_mini__HC2st:not(:first-of-type) {
  margin-left: -0.5rem;
}
.styles-module_group_item_small__LLTZH:not(:first-of-type) {
  margin-left: -0.75rem;
}

.styles-module_group_item_text__grGO- {
  background: var(--avatarTextBackground, var(--secondary));
  color: var(--onSecondary);
}

.styles-module_root__99jOU {
  overflow: hidden;
}
.styles-module_main__nekvR {
  display: inline-block;
}

.styles-module_received __xTYWs {
  text-align: left;
}

.styles-module_sent__xAHof {
  text-align: right;
  float: right;
}

.styles-module_item__4jVIN {
  margin-bottom: 0.25rem;
}

.styles-module_message__Pll-Z {
  text-align: left;
  white-space: pre-line;
  border: 1px solid;
  border-radius: var(--cardRadius-2);
  max-width: 21.25rem;
  padding: 1rem;
}

.styles-module_message__Pll-Z a {
  text-decoration: underline;
}

.styles-module_message_received__92ql5 {
  background-color: var(--white);
  border-color: var(--stroke-1);
}

.styles-module_message_sent__J99bd {
  color: var(--background);
  background-color: var(--text-1);
}

.styles-module_message_error__ypXu6 {
  background-color: var(--error-2);
  border-color: var(--error-1);
}

.styles-module_avatar__ONry2 {
  display: none;
}

.styles-module_name__3dbU0 {
  font-size: 0;
}

.styles-module_title__-mIko {
  margin-left: 0.5rem;
}

@supports (display: grid) {
  .styles-module_main__nekvR {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(3, auto);
    row-gap: 0.25rem;
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }

  .styles-module_item__4jVIN {
    margin-bottom: 0;
  }

  .styles-module_received__fZTWX > * {
    grid-column: 2;
  }

  .styles-module_sent__xAHof > * {
    grid-column: 1;
  }

  .styles-module_avatar__ONry2 {
    grid-row: 2;
    align-self: flex-end;
    display: block;
  }

  .styles-module_received__fZTWX .styles-module_avatar__ONry2 {
    grid-column: 1;
  }

  .styles-module_sent__xAHof .styles-module_avatar__ONry2 {
    grid-column: 2;
  }
}

.styles-module_root__UTE3- {
  display: inline-block;
  position: relative;
}

.styles-module_title__wgZ8- {
  border: 1px solid;
  border-radius: 4px;
  padding: 0.375rem 0.625rem;
  width: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px var(--stroke-2);
}

.styles-module_title__wgZ8-:focus {
  box-shadow: var(--focus);
}

.styles-module_open__UVIi4 {
  color: var(--background);
  background-color: var(--inputText);
}

.styles-module_close__UkeIn {
  color: var(--inputText);
  border-color: var(--buttonStroke);
  background-color: var(--background);
}

.styles-module_close__UkeIn:hover {
  background-color: var(--onBackground);
}

.styles-module_title__wgZ8- p:not(:last-child) {
  margin-right: 0.25rem;
}

.styles-module_body__Qb4Br {
  width: 15.625rem;
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  z-index: 100;
}

.styles-module_container__BmWlL {
  background-color: var(--background);
  border-radius: var(--cardRadius-1);
  border: 1px solid var(--stroke-1);
  text-align: center;
  position: relative;
  overflow: hidden;
}

.styles-module_container__BmWlL:focus {
  box-shadow: var(--focus) !important;
}

.styles-module_body__J2i-9 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.styles-module_caption__4SHaF {
  background-color: var(--onBackgroundActive);
  width: 100%;
  padding: 1rem;
  top: 0;
  left: 0;
  z-index: 1;
}

.styles-module_weekdays__mDxLe,
.styles-module_body__J2i-9 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles-module_week__AWp1W,
.styles-module_weekdaysRow__1pY-A {
  display: flex;
}

.styles-module_day__w2e0b,
.styles-module_weekday__tNDde {
  width: 14.285714286%;
  position: relative;
}

.styles-module_day__w2e0b {
  font-size: 1.125rem;
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-1);
  padding: 0.5rem 0;
}

.styles-module_selected__L-roE:not(.styles-module_outside__ueYYg) {
  color: var(--onSecondary);
  background-color: var(--secondary);
}

.styles-module_disabled__633OV:not(.styles-module_outside__ueYYg) {
  color: var(--text-3);
  background-color: var(--stroke-1);
}

.styles-module_day__w2e0b:not(:first-of-type) {
  margin-left: 4px;
}

.styles-module_day__w2e0b:focus {
  border-color: transparent;
}

.styles-module_day__w2e0b:not(.styles-module_selected__L-roE):not(.styles-module_disabled__633OV):not(.styles-module_outside__ueYYg):hover {
  background-color: var(--onBackgroundActive);
  border-color: var(--stroke-2);
  cursor: pointer;
}

.styles-module_outside__ueYYg {
  border-color: transparent;
}

.styles-module_week__AWp1W:not(:last-of-type) {
  margin-bottom: 4px;
}

.styles-module_weekday__tNDde {
  padding-top: 0.875rem;
}

.styles-module_weekday__tNDde abbr {
  text-decoration: none;
}

.styles-module_navBar__LQWns {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
}

.styles-module_navBarItem__u9ZNH {
  background-color: var(--background);
  color: var(--text-2);
  border: 1px solid var(--stroke-1);
  border-radius: 100%;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.styles-module_navBarItem__u9ZNH:disabled {
  color: var(--text-3);
  background-color: var(--stroke-1);
  cursor: auto;
}

.styles-module_today__eKEZa,
.styles-module_month__QfOYM,
.styles-module_weekNumber__0mY-l {
}

.styles-module_root__4qTrq {
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  width: 100%;
}

.styles-module_root__4qTrq em {
  color: var(--text-1);
}

.styles-module_default__C0LG- {
  background-color: var(--onBackground);
  border-radius: var(--cardRadius-2);
}

.styles-module_floating__cw7OR {
  border: 1px solid var(--stroke-1);
  border-radius: 6.25rem;
  border-radius: var(--inputRadius);
}

.styles-module_title__Lt9Ho {
  text-align: left;
  flex: 1 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 1rem;
}

.styles-module_icon__-YMh3 {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.styles-module_chevron__oSccr {
  margin-left: 0.25rem;
}

.styles-module_root__QpTA7 {
  --margin: var(--spacingL);
  width: 100%;
  margin-top: var(--margin);
  margin-bottom: var(--margin);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--spacingS);
  padding-right: var(--spacingS);
}

.styles-module_remove-vertical-margin__PBXt4 {
  margin-top: 0;
  margin-bottom: 0;
}

.styles-module_wide__u--N4 {
  max-width: calc(35rem + var(--spacingS) * 2);
}

.styles-module_narrow__LX9rX {
  max-width: calc(30rem + var(--spacingS) * 2);
}

.styles-module_title__-Yrz5 {
  text-align: center;
}

@media (min-width: 768px) {
  .styles-module_root__QpTA7 {
    --margin: var(--spacingXL);
  }
}

@media (min-width: 1440px) {
  .styles-module_root__QpTA7 {
    --margin: var(--spacingXXL);
  }
}

.styles-module_root__7F-QS {
  text-align: center;
  background-color: var(--onBackgroundActive);
  border-radius: var(--cardRadius-2);
  padding: 1.5rem;
}

.styles-module_close__duu9O {
  display: flex;
}
.styles-module_clostBtn__yX86n {
  margin-left: auto;
}

.styles-module_root__vkywA {
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-1);
  overflow: hidden;
  display: grid;
  grid-template-columns: 5.125rem auto;
}

.styles-module_date__5tCOT {
  text-align: center;
  background-color: var(--onBackgroundActive);
  padding: var(--spacingS) 0;
  display: inline-block;
}

.styles-module_body__1CyrT {
  padding-left: var(--spacingM);
  padding-right: var(--spacingM);
  flex-grow: 1;
}

.styles-module_inner__MwW-9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacingS) 0;
  height: 100%;
}

.styles-module_inner-body__N8iwA {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.styles-module_title__3VAav {
  padding-top: var(--spacingXXS);
}

.styles-module_empty__h2Rh4 {
  background-color: var(--onBackground);
  border-radius: var(--cardRadius-2);
  min-height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles-module_root__m5NCo {
  background-color: var(--background);
  border-bottom: 1px solid var(--stroke-1);
  padding: 0.75rem 1rem;
  min-height: 3.5625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
}

.styles-module_title__9y4DB {
  color: var(--inputText);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  align-self: center;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  grid-column: 2;
}

.styles-module_title__9y4DB.styles-module_hasIcon__HmnE0 {
  padding-left: 3.25rem;
}

.styles-module_icon__EdYMW {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}

.styles-module_icon__EdYMW > * {
  width: 100%;
  height: 100%;
}

.styles-module_link__mC8d7 {
  flex-shrink: 0;
}

.styles-module_previous__mhp71 {
  justify-self: start;
}
.styles-module_close__-2f4a {
  justify-self: end;
}

@supports (display: grid) {
  .styles-module_root__m5NCo {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }
  .styles-module_title__9y4DB {
    margin: 0;
  }
}

.styles-module_item__eAhR6 > div:last-of-type {
  padding: 1.5rem 0;
}

.styles-module_item__eAhR6.styles-module_open__P9bQz > div:last-of-type {
  border-bottom: 1px solid var(--stroke-1);
}

.styles-module_centering__2fYmp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles-module_title__pPXQp {
  border-bottom: 1px solid var(--stroke-1);
  padding: 1.25rem 0;
}

.styles-module_title__pPXQp.styles-module_open__P9bQz {
  border-bottom: none;
}

.styles-module_title__pPXQp > * {
  white-space: normal;
}

.styles-module_titleChildrenContainer__J3WgM {
  display: flex;
}

.styles-module_root__wPvhn {
  color: var(--text-1);
  box-shadow: 0.0625rem var(--inputStroke);
  border-radius: var(--cardRadius-2);
  background-color: var(--finnBackground);
}

.styles-module_waiting__RKfXb {
  background-color: var(--onBackground);
}

.styles-module_stopped__n1Z2X {
  background-color: var(--warning-1);
}

.styles-module_open__M-jAk {
  border-bottom: 1px solid var(--background);
}

.styles-module_section__IOhGA {
  padding: 1rem;
}

.styles-module_logo__c29ns {
  width: 3.625rem;
  height: 1.125rem;
}

.styles-module_header__0gP9d {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.styles-module_header__0gP9d > :last-child {
  display: inline-flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.styles-module_body__-7SEZ {
  display: flex;
  align-items: center;
}

.styles-module_img__e5zM2 {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}

.styles-module_status__pbhYw {
  overflow: hidden;
  cursor: auto;
}

.styles-module_bodyWrapper__Nqrq4 {
}

.styles-module_bodyWrapper__Nqrq4 a {
  padding-left: 0 !important;
}

.styles-module_ellipsis__QuqHX {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles-module_root__qjN4D {
  position: relative;
  border: 1px solid var(--stroke-1);
  background-color: var(--background);
  border-radius: var(--cardRadius-2);
  overflow: hidden;
}

.styles-module_header__aH5-U {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.125rem 1.5rem;
  border-bottom: 1px solid var(--stroke-1);
}

.styles-module_container__heKHC {
  text-align: center;
  padding: 1.5rem;
  position: relative;
  background-color: var(--onBackground);
}

.styles-module_main__pjq5P {
  margin-bottom: 1.625rem;
  background-color: var(--background);
  box-shadow: 0 0 0 1px var(--stroke-1);
  border-radius: var(--cardRadius-1);
  overflow: hidden;
}

.styles-module_footer__VrAuR > * {
  grid-row: 1;
}

.styles-module_footerWrapper__vNB4- {
  display: flex;
  justify-content: center;
  padding: 1.125rem;
  border-top: 1px solid var(--stroke-1);
}

.styles-module_footer__VrAuR {
  display: inline-grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
  justify-items: center;
  background-color: var(--background);
}

@media (max-width: 60.5em) {
  .styles-module_download__spT50 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% - 8.125rem);
    z-index: 10;
  }
}

.styles-module_player__weFMD {
  background-color: var(--stroke-2);
  border-radius: var(--cardRadius-1);
  padding-top: 56.25%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.styles-module_media__3ipL0 {
  vertical-align: top;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.styles-module_placeholder__Y6-nf::before {
  content: "";
  background-color: var(--stroke-2);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.styles-module_button__p8bo1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.styles-module_root__Ef-vQ {
  position: relative;
  --slideshowGap: 1rem;
}

.styles-module_container__4QXj- {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  gap: var(--slideshowGap);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.styles-module_container__4QXj-::-webkit-scrollbar {
  display: none;
}

.styles-module_child__05168 {
  width: 100%;
  box-sizing: content-box;
  font-size: 0;
  flex-shrink: 0;
  display: inline-block;
  scroll-snap-align: start;
}

.styles-module_child__05168 > * {
  font-size: 1rem;
  height: 100%;
  box-sizing: border-box;
}

.styles-module_bar__ieAsk {
  max-width: var(--maxContentWidth);
  padding-left: var(--contentOffset);
  padding-right: var(--contentOffset);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.styles-module_buttons__Ry-Ya {
  display: flex;
  gap: 8px;
}

.styles-module_button__BPRcb:disabled > :last-child {
  cursor: auto;
  color: var(--text-3);
  background-color: var(--stroke-1);
  border-color: var(--stroke-2);
}

@media (min-width: 37.5em) {
  .styles-module_child__05168 {
    width: calc((100% - var(--slideshowGap)) / 2);
  }
}

@media (min-width: 53.125em) {
  .styles-module_root__Ef-vQ::before {
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      #fff 1.5rem,
      #fff 1.5rem
    );
  }
  .styles-module_root__Ef-vQ::after {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #fff 1.5rem,
      #fff 1.5rem
    );
  }

  .styles-module_child__05168 {
    width: calc((100% - var(--slideshowGap) * 2) / 3);
  }
}

.styles-module_root__t6-Bo {
  background-color: var(--onBackgroundActive);
  color: var(--inputText);
  border: 1px solid var(--stroke-1);
  border-width: 0;
  border-bottom-width: 1px;
  padding: 1rem 1rem 0.75rem;
  min-width: 14.0625rem;
}

.styles-module_banner__PApK- {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles-module_main__zL0hO {
  margin-top: 1.5rem;
}

.styles-module_group__pmS3n:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

@media (min-width: 40.625em) {
  .styles-module_menu-toggle__jZsXt {
    display: none;
  }
  .styles-module_display__sNd6W {
    display: flex;
    flex-direction: column;
  }
  .styles-module_root__t6-Bo {
    padding: 1.75rem 0.75rem;
    border-right-width: 1px;
    border-bottom-width: 0;
  }

  .styles-module_logo__Nmwno {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .styles-module_banner__PApK- {
    margin-bottom: 2.375rem;
  }

  .styles-module_main__zL0hO {
    flex-grow: 1;
    visibility: visible;
    height: auto;
  }

  .styles-module_navigation__j1Yhr {
    flex-grow: 1;
  }

  .styles-module_group__pmS3n:not(:first-child) {
    margin-top: auto;
  }
}

.styles-module_header__Xfx5M {
  border-bottom: 1px solid var(--stroke-1);
  padding: 1.125rem var(--spacingS);
  margin-bottom: var(--spacingS);
}

.styles-module_navigation__z45MD {
  margin: var(--spacingXXS);
}

.styles-module_menu-item__tk6ud {
  border-radius: var(--cardRadius-2);
  width: 100%;
  padding: 0.5rem 0.75rem;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: flex-end;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.styles-module_menu-item__tk6ud.styles-module_expanded__nwdz5 {
  padding: 0.75rem 1rem;
}

.styles-module_item__waFB- {
  display: flex;
  align-items: center;
}

.styles-module_menu-item__tk6ud:not(.styles-module_active__3nirs):hover {
  background-color: var(--onBackgroundActive);
}

.styles-module_menu-item__tk6ud > :first-child {
  transition: transform 0.125s;
}

.styles-module_menu-item__tk6ud:not(.styles-module_active__3nirs):hover > :first-child {
  transform: translateX(0.25rem);
}

.styles-module_menu-item__tk6ud.styles-module_active__3nirs {
  --avatarBorderColor: var(--inputText);
  --avatarTextBackground: var(--secondaryHover);
  background-color: var(--inputText);
  color: var(--background);
}

.styles-module_icon__Hg3dV {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.styles-module_badge__asfYI {
  color: var(--background);
  background-color: var(--confirm-1);
  border-radius: 6.25rem;
  padding: 0.4rem;
  height: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.styles-module_children__-zQDA {
  min-width: 0;
  max-width: 100%;
}

.styles-module_root__88EQX {
  border-bottom: 1px solid var(--stroke-1);
}

.styles-module_list__TBfSZ {
  display: flex;
}

.styles-module_item__-lzVG:not(:last-of-type) {
  margin-right: var(--spacingM);
}

.styles-module_link__WGa3P {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  height: 100%;
  display: block;
  position: relative;
}

.styles-module_link__WGa3P:hover {
  color: var(--primary);
}

.styles-module_link__WGa3P::before {
  content: "";
  background-color: currentColor;
  opacity: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.styles-module_link__WGa3P.styles-module_active__k2aUM {
  font-weight: 500;
  color: var(--primary);
}

.styles-module_link__WGa3P.styles-module_active__k2aUM::before {
  opacity: 1;
}

.styles-module_list__lL-5H {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: var(--spacingS);
  gap: var(--spacingS);
}

.styles-module_value__w0LKK {
  text-align: right;
}

.styles-module_root__2eT7A {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  --offset: var(--spacingS);
}

.styles-module_header__p09bc {
  border-bottom: 1px solid var(--stroke-1);
  min-height: 3.5rem;
  padding: var(--spacingXS) var(--spacingS);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles-module_title__JRJ-Q {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.styles-module_main__kc5qM {
  background-color: var(--onBackground);
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.styles-module_content__GaRzC {
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.styles-module_inner__osIXn {
  max-width: 55rem;
  margin: 0 auto;
  position: relative;
}

.styles-module_asideContent__1k2rt {
  background-color: var(--background);
  border-left: 1px solid var(--stroke-1);
  padding-top: 3.5rem;
  width: 350px;
  max-width: 90%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.styles-module_asideContent__1k2rt > .styles-module_inner__osIXn {
  padding: var(--offset);
  display: flex;
  flex-direction: column;
}

.styles-module_documentActions__JRd4I {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.styles-module_toggle__Gk8By {
  position: absolute;
  top: var(--offset);
  right: var(--offset);
  z-index: 100;
}

@media (min-width: 1000px) {
  .styles-module_root__2eT7A {
    --offset: var(--spacingM);
  }
  .styles-module_main__kc5qM.styles-module_sidebar__CFapB {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 350px);
  }
  .styles-module_asideContent__1k2rt {
    border-left: 0;
    width: auto;
    max-width: 100%;
    position: static;
    padding-top: 0;
  }

  .styles-module_aside__sEtgB {
    background-color: var(--background);
    border-left: 1px solid var(--stroke-1);
  }
}

.styles-module_root__jNnpt {
  display: flex;
  align-items: center;
}

.styles-module_icon__MenmW {
  color: var(--buttonStroke);
  margin-left: 0.25rem;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}
.styles-module_iconContainer__y-UIa {
  position: relative;
}

.styles-module_icon__MenmW:not(.styles-module_success__-yDIr, .styles-module_error__Gw2Wy):hover {
  color: var(--inputStroke);
}

.styles-module_iconTooltip__ykYPP {
  display: none;
}

.styles-module_iconTooltip__ykYPP:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  bottom: 0;
  transform: translateY(100%) translateX(-50%);
  left: 50%;
  border-width: 0.375rem 0.40625rem 0 0.40625rem;
  border-color: var(--secondary) transparent transparent transparent;
}


.styles-module_iconContainer__y-UIa:hover>.styles-module_iconTooltip__ykYPP {
  display: block;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  font-size: 0.8125rem;
  padding: 8px;
  background-color: var(--secondary);
  border-radius: 0.25rem;
  color: var(--onSecondary);
}

/* hard to override styles, lazy */
.styles-module_successTooltip__3lK3z {
  background-color: var(--primary)!important;
  color: var(--onPrimary)!important;
}

.styles-module_successTooltip__3lK3z:after {
  display: block!important;
  border-color: var(--primary) transparent transparent transparent!important;
}

.styles-module_success__-yDIr {
  color: var(--confirm-1);
}

.styles-module_error__Gw2Wy {
  color: var(--error-1);
}

.styles-module_iconContainer__7N6HA {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles-module_iconContainer__7N6HA:hover>.styles-module_iconTooltip__c1aJz {
  display: block;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  font-size: 0.8125rem;
  padding: 8px;
  background-color: var(--secondary);
  border-radius: 0.25rem;
  color: var(--onSecondary);
}

.styles-module_iconTooltip__c1aJz {
  display: none;
  margin: 0 auto;
  white-space: nowrap;
}

.styles-module_iconTooltip__c1aJz:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: "";
  position: absolute;
  line-height: 1;
  bottom: 0;
  transform: translateY(100%) translateX(-50%);
  left: 50%;
  border-width: 0.375rem 0.40625rem 0 0.40625rem;
  border-color: var(--secondary) transparent transparent transparent;
}


.styles-module_iconContainer__7N6HA:hover>.styles-module_iconTooltip__c1aJz {
  display: flex;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  font-size: 0.8125rem;
  padding: 8px;
  background-color: var(--secondary);
  border-radius: 0.25rem;
  color: var(--onSecondary);
}

.styles-module_root__jjqRp {
  background-color: var(--text-1);
  color: var(--background);
  border-radius: 10px;
  padding: 5px;
  height: 1.25rem;
  min-width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
}

.styles-module_root__o4y-p {
  max-width: 329px;
}

.styles-module_image__h6qHz img {
  border-radius: var(--cardRadius-1);
}

.styles-module_no-image__O4Sgt {
  width: 100%;
  padding-top: 100%;
  border-radius: var(--cardRadius-1);
  background-color: var(--onBackground);
}

:root {
  --reach-dialog: 1;
}

.styles-module_backdrop__p73p8 {
  background: rgba(0, 52, 62, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 999;
}

.styles-module_body__jAJiu {
  text-align: center;
  background-color: var(--background);
  border-radius: 0.5rem;
  margin: 0;
  max-width: 25rem;
  width: calc(100% - 1rem);
  max-height: calc(100vh - 2rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  overflow-y: auto;
}

.styles-module_s__R0sOX {
  max-width: 20rem;
}

.styles-module_m__THegP {
  max-width: 25rem;
}

.styles-module_l__cyOyZ {
  max-width: 30rem;
}

.styles-module_body-full__aBdhc {
  background-color: var(--background);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.styles-module_content__B85I2 {
  padding: 1rem;
}

.styles-module_close__-9p0v {
  float: right;
  position: sticky;
  top: 1rem;
  right: 0;
}

.styles-module_close-full__3G9ve {
  right: 1rem;
}

.styles-module_inner-content__2XaiI {
  clear: both;
}

@media (min-width: 23.4375em) {
  .styles-module_content__B85I2 {
    padding: 1.5rem;
  }
  .styles-module_body__jAJiu {
    width: calc(100% - 2rem);
  }
}

@media (min-width: 29em) {
  .styles-module_content__B85I2 {
    padding: 2rem;
  }
}

.styles-module_modal__OxVIG {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.styles-module_footer__Qhdt4 {
  background-color: var(--onBackground);
  border-top: 1px solid var(--stroke-1);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.styles-module_footerButtons__lvou8 {
  display: flex;
  gap: 1rem;
}

.styles-module_footerButtonsFW__XCYW8 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.styles-module_content__fFyjx {
  padding-left: 1rem;
  padding-right: 1rem;
}

.styles-module_submit__0HV7- {
  width: auto;
}

.styles-module_main__epXy6 {
  padding-bottom: 1rem;
}

@media (min-width: 23.4375em) {
  .styles-module_content__fFyjx {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .styles-module_main__epXy6 {
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 29em) {
  .styles-module_content__fFyjx {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .styles-module_main__epXy6 {
    padding-bottom: 2rem;
  }
}

.styles-module_header__2VpcS {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.styles-module_root__BNlK- {
  background-color: var(--background);
  border-radius: var(--cardRadius-1);
  box-shadow: 0 0 0 1px var(--stroke-1), 0px 1px 4px var(--stroke-1);
}

.styles-module_table__u86d0 {
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.styles-module_narrow__gZRMK {
  --tableSpacing: 0.5rem;
}

.styles-module_default__PNBZd {
  --tableSpacing: 1rem;
}

.styles-module_expanded__Bg3wQ {
  --tableSpacing: 1.5rem;
}

.styles-module_cell-header__gK-rY,
.styles-module_cell-item__IZfCU {
  padding-top: var(--tableSpacing);
  padding-bottom: var(--tableSpacing);
}

.styles-module_cell-header__gK-rY:first-of-type,
.styles-module_cell-item__IZfCU {
  padding-left: 1rem;
}
.styles-module_cell-header__gK-rY:last-of-type,
.styles-module_cell-item__IZfCU:last-of-type {
  padding-right: 1rem;
}

.styles-module_row__lDLMi:hover {
  background-color: var(--onBackgroundActive);
}

.styles-module_row__lDLMi:not(:last-of-type) .styles-module_cell-item__IZfCU,
.styles-module_cell-header__gK-rY {
  border-bottom: 1px solid var(--stroke-1);
}

.styles-module_cell-item__IZfCU:last-of-type {
  text-align: right;
}

.styles-module_cell-header__gK-rY > div {
  display: flex;
  align-items: center;
}

.styles-module_icon__m-fJC {
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
}

.styles-module_list__zTGuJ {
  --rowGap: 1rem;
  --columnGap: 1.25rem;
  display: flex;
  flex-wrap: wrap;
}

.styles-module_string__CSdBN {
  font-size: 0.875rem;
  font-weight: 500;
}

.styles-module_key__RNbPX {
  font-size: 0.875rem;
  width: calc(50% - var(--columnGap));
  margin-right: var(--columnGap);
  margin-bottom: var(--rowGap);
}

.styles-module_value__7E1dq {
  width: 50%;
  margin-bottom: var(--rowGap);
}

@supports (display: grid) {
  .styles-module_list__zTGuJ {
    display: grid;
    grid-template-columns: auto 1fr;
    -moz-column-gap: var(--columnGap);
         column-gap: var(--columnGap);
    row-gap: var(--rowGap);
  }

  .styles-module_key__RNbPX,
  .styles-module_value__7E1dq {
    width: auto;
    margin: 0;
  }
}
