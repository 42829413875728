.spacing_xxs {
  margin-bottom: var(--spacingXXS);
}

.spacing_xs {
  margin-bottom: var(--spacingXS);
}

.spacing_s {
  margin-bottom: var(--spacingS);
}

.spacing_m {
  margin-bottom: var(--spacingM);
}

.spacing_l {
  margin-bottom: var(--spacingL);
}

.spacing_xl {
  margin-bottom: var(--spacingXL);
}

.spacing_xxl {
  margin-bottom: var(--spacingXL);
}

.responsive.spacing_xs {
  margin-bottom: 0.625rem;
}
.responsive.spacing_s {
  margin-bottom: var(--spacingXS);
}
.responsive.spacing_m {
  margin-bottom: 1.25rem;
}
.responsive.spacing_l {
  margin-bottom: var(--spacingM);
}
.responsive.spacing_xl {
  margin-bottom: 2.5rem;
}
.responsive.spacing_xxl {
  margin-bottom: var(--spacingXL);
}

@media (min-width: 375px) {
  .responsive.spacing_xs {
    margin-bottom: var(--spacingXS);
  }
  .responsive.spacing_s {
    margin-bottom: var(--spacingS);
  }
  .responsive.spacing_m {
    margin-bottom: var(--spacingM);
  }
  .responsive.spacing_l {
    margin-bottom: var(--spacingL);
  }
  .responsive.spacing_xl {
    margin-bottom: var(--spacingXL);
  }
  .responsive.spacing_xxl {
    margin-bottom: var(--spacingXXL);
  }
}

@media (min-width: 768px) {
  .responsive.spacing_xxs {
    margin-bottom: var(--spacingXS);
  }
  .responsive.spacing_xs {
    margin-bottom: var(--spacingS);
  }
  .responsive.spacing_s {
    margin-bottom: var(--spacingM);
  }
  .responsive.spacing_m {
    margin-bottom: var(--spacingL);
  }
  .responsive.spacing_l {
    margin-bottom: var(--spacingXL);
  }
  .responsive.spacing_xl {
    margin-bottom: 4.5rem;
  }
  .responsive.spacing_xxl {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1440px) {
  .responsive.spacing_m {
    margin-bottom: 2.5rem;
  }
  .responsive.spacing_l {
    margin-bottom: var(--spacingXXL);
  }
  .responsive.spacing_xl {
    margin-bottom: 5.5rem;
  }
  .responsive.spacing_xxl {
    margin-bottom: 9rem;
  }
}
