@import "./_reset.css";

@font-face {
  font-family: "DNB";
  src: url(./fonts/DNB-Regular.woff2) format("woff2"),
    url(./fonts/DNB-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DNB";
  src: url(./fonts/DNB-Medium.woff2) format("woff2"),
    url(./fonts/DNB-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:root {
  --fontXXS: 0.8125rem;
  --fontXS: 0.875rem;
  --fontS: 1rem;
  --fontM: 1.125rem;
  --fontL: 1.375rem;
  --fontXL: 1.75rem;
  --fontXXL: 2rem;
  --spacingXXS: 0.5rem;
  --spacingXS: 0.75rem;
  --spacingS: 1rem;
  --spacingM: 1.5rem;
  --spacingL: 2rem;
  --spacingXL: 3rem;
  --spacingXXL: 4rem;
  --focus: 0 0 0 2px var(--primary), 0 0 0 6px rgba(40, 180, 130, 0.2);
}

body {
  color: var(--text-1);
  background-color: var(--background);
  font-family: "DNB", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

:focus {
  outline: 0;
  box-shadow: var(--focus);
}

.main:focus {
  box-shadow: none;
}

.root {
  height: 100vh;
}

.view-messages {
  overflow: hidden;
}
.view-messages .root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.view-messages .main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.view-messages .main-inner {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.view-full .root {
  display: block;
}

@media (min-width: 40.625em) {
  html {
    overflow: hidden;
  }
  .root {
    display: grid;
    grid-template-columns: auto 1fr;
    overflow: hidden;
  }

  .view-messages .root {
    flex-direction: row;
  }

  body:not(.view-messages) .main {
    overflow-y: auto;
  }
}

/* 
 !! SUPER HACKY
 This is to target only *Safari*. -webkit-fill-available affects Chrome as well, 
 and does not work the same way as in Safari.
 This makes sure that we are never taller then the viewport height, important for messages.
 There has to be a better way to do this, but for now this works... 
*/
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .view-messages .root {
      height: -webkit-fill-available;
    }
    .root {
      height: -webkit-fill-available;
    }
  }
}

@media (max-width: 40.625em) {
  .root {
    height: auto;
  }
}
