
    :root {
      --cardRadius-1: 0.25rem;
--cardRadius-2: 0.5rem;
--buttonRadius-1: 6.25rem;
--buttonRadius-2: 3.125rem;
--inputRadius: 0.25rem;
--iconRadius-1: 0.75rem;
--iconRadius-2: 1.25rem;
      --primary: #007070;
--primaryHover: #006161;
--onPrimary: #ffffff;
--secondary: #d1f0e9;
--secondaryHover: #a5e1d2;
--onSecondary: #007070;
--text-1: #00333d;
--text-2: #335d66;
--text-3: #80999d;
--stroke-1: #e5eaeb;
--stroke-2: #cbd5d7;
--background: #ffffff;
--onBackground: #f9fafa;
--onBackgroundActive: #f4fbf9;
--confirm-1: #009e77;
--confirm-2: #e8f8f3;
--onConfirm-2: #00333d;
--error-1: #e00074;
--error-2: #fdecf5;
--onError-2: #00333d;
--warning-1: #ffeac2;
--warning-2: #534937;
--buttonStroke: #007070;
--inputStroke: #007070;
--inputStrokeFocus: #00303d;
--inputText: #007070;
--inputTextPlaceholder: #80999d;
--finnBackground: #f1f9ff;
    }
    