.header {
  display: flex;
  align-items: center;
  background-color: var(--background);
  border-bottom: 1px solid var(--stroke-1);
  padding: var(--spacingS);
  flex-shrink: 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.header.spacing {
  margin-bottom: var(--spacingS);
}

.back {
  margin-right: var(--spacingS);
}
