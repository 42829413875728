:root {
  --reach-dialog: 1;
}

.root {
  max-width: 45rem;
  width: 45rem;
  overflow-y: hidden;
}

.backdrop {
  background: rgba(0, 52, 62, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.body {
  background-color: var(--background);
  border-radius: 0.5rem;
  margin: 0;
  max-width: 45rem;
  height: 37rem;
  width: calc(100% - 1rem);
  max-height: calc(100vh - 2rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
}

.templatesContainer {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem 0;
  max-width: 100%;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
}

.buttons::before {
  content: unset;
}

.tabs {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.title {
  padding: 1.5rem 1.5rem 0 1.5rem;
}


.backButtonContainer {
  padding: .5rem 1rem .25rem;
  border-bottom: 1px solid var(--stroke-1);
}

.backButtonContainer .backButton{
  color: var(--text-2);
}

.body .backButtonContainer .backButton>span {
  background-color: unset;
  font-weight: 400;
  border: unset;
  color: var(--text-1);
  margin: 0;
}

.form {
  padding: 1.5rem 1.5rem .5rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.addButton {
  height: 100%;
}


.favoriteTemplates {
  padding: var(--spacingS);
  display: flex;
  gap: .5rem;
  max-width: 100%;
  flex-wrap: wrap;
}
