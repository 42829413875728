.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fieldset {
  width: 100%;
}

.fieldset[data-prefix]:before {
  content: attr(data-prefix);
  color: var(--text-3);
}

@media (min-width: 56.25em) {
  .expanded {
    position: relative;
  }

  .expanded[data-prefix]:before {
    padding-right: 0.5rem;
    position: absolute;
    top: 0;
    right: 100%;
  }
}
