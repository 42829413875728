.root {
  --margin: var(--spacingL);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--spacingS);
  padding-right: var(--spacingS);
}

.paddingS {
  padding-left: var(--spacingS);
  padding-right: var(--spacingS);
}

.paddingM {
  padding-left: var(--spacingM);
  padding-right: var(--spacingM);
}

.paddingL {
  padding-left: var(--spacingL);
  padding-right: var(--spacingL);
}

.paddingXL {
  padding-left: var(--spacingXL);
  padding-right: var(--spacingXL);
}

.spacing {
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}

.width_large {
  max-width: calc(40rem + var(--spacingS) * 2);
  composes: root;
}

.width_default {
  max-width: calc(35rem + var(--spacingS) * 2);
  composes: root;
}

.width_narrow {
  max-width: calc(30rem + var(--spacingS) * 2);
  composes: root;
}

.width_full {
  max-width: calc(110rem + var(--spacingS) * 2);
  composes: root;
}

.title {
  text-align: center;
}

@media (min-width: 768px) {
  .root {
    --margin: var(--spacingXL);
  }
}

@media (min-width: 1440px) {
  .root {
    --margin: var(--spacingXXL);
  }
}
