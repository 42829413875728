@value medium: 60.5em;

.form {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: top;
  gap: .5rem;
  position: relative;
  width: 100%;
}

.input {
  max-height: 25vh;
  margin-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  min-height: unset;
  resize: none;
}

.button {
  width: auto;
}

.smallButton {
  --iconSize: 3rem;
}

.smallButton span {
  color: var(--onPrimary);
  background-color: var(--primary);
  border-color: var(--primary);
}

.smallButton svg {
  width: 1.5rem;
  height: 1.5rem;
}

.messageTemplatesButtonContainer {
  width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.messageTemplatesButton {
  position: absolute;
  line-height: 1rem;
  cursor: pointer;
  display: flex;
  left: -3.25rem;
  padding: .5rem;
  margin: 0;
  background: rgba(0,0,0,0);
}

.formContainer {
  border-top: 1px solid var(--stroke-1);
  padding: 1rem;
}

@supports (padding: max(0px)) {
  .formContainer {
    padding-bottom: max(1rem, calc(env(safe-area-inset-bottom) + 0.5rem));
  }
}

.paddingRight {
  padding-right: 3rem;
}
