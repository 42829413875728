.root {
  overflow: auto;
}

.header {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  border-bottom: 1px solid var(--stroke-1);
  padding: var(--spacingS);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link:hover {
  background-color: var(--onBackgroundActive);
}

.bold {
  font-weight: 500;
}

.propertyResultsContainer {
  padding: var(--spacingS);
  
  & > nav:not(:first-child){
    margin-top: var(--spacingXL);
  }

  & > nav:first-child > div > div > span {
    z-index: 2;
  }
}


.filterGroup {
  margin-bottom: var(--spacingS);
  padding-left: var(--spacingXS);

  & > ul > li {
    margin-bottom: var(--spacingXS);
    display: block;
  }
}

.filterGroupHeader {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--spacingXS);

  & > h3 {
    font-size: 14px;
    font-weight: 500;
    padding-left: var(--spacingXXS);
    padding-right: var(--spacingXXS);
  }
}