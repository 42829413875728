@value mediumScreen: (min-width: 54em);
@value largeScreen: (min-width: 64em);

.headerAside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > * {
    margin-bottom: var(--spacingXS);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  width: 100%;
}

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navigation {
  overflow-y: auto;
}

.navigationlist,
.filterInputContainer {
  margin-left: var(--spacingXXS);
  margin-right: var(--spacingXXS);
}

.nested,
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

@media mediumScreen {
  .root:not(.nested) {
    flex-direction: row;
  }

  .root:not(.nested) > .navigation {
    border-right: 1px solid var(--stroke-1);
    width: 18.5rem;
    flex-shrink: 0;
  }
}

@media largeScreen {
  .root {
    flex-direction: row;
  }
  .navigation {
    border-right: 1px solid var(--stroke-1);
    width: 16.5rem;
    flex-shrink: 0;
  }
}
