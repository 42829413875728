.badges {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.625rem;
}

.badges > *:first-child:last-child {
  grid-column: 1 / -1;
}

.menuItem:hover .markUnread {
  display: block;
}

.menuItem:not(:hover) .badges {
  grid-template-columns: auto;
}

.markUnread {
  display: none;
  cursor: pointer;
  position: relative;
  height: 1.25rem;
  margin-left: 0.25rem;
}

.markUnreadTooltip {
  position: fixed;
  z-index: 1000;
  color: var(--onSecondary);
  background-color: var(--secondary);
  padding: var(--spacingXXS);
  transform: translateX(-50%) translateY(-100%);
  border-radius: 0.25rem;
  line-height: 1rem;
}

.markUnreadTooltip:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6.5px 0 6.5px;
  border-color: var(--secondary) transparent transparent transparent;
}
