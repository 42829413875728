.saleCard {
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--stroke-1);
  padding: var(--spacingXS);
  display: flex;
  justify-content: space-between;
  align-items: center;


  & .title {
    font-weight: 400;

    &::first-letter {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  & .unreadMessages {
    position: relative;
    top: -10px;
    left: -7px;
  }

  &:hover {
    background-color: var(--onBackgroundActive);
  }
}
