.badge {
  --accentYellow: #fff5e2;
  --accentYellow-text: #534937;
  border-radius: var(--cardRadius-1);
  padding: 0.1875rem 0.375rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  flex-shrink: 0;
}

.circle {
  color: var(--background);
  background-color: var(--confirm-1);
  border-radius: 6.25rem;
  padding: 0.4rem;
  height: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.outline {
  color: var(--inputText);
  border: 1px solid var(--stroke-1);
  background-color: var(--background);
  composes: badge;
}

.dot {
  color: var(--background);
  background-color: var(--error-1);
  border-radius: 6.25rem;
  padding: 0.4rem;
  height: 0.625rem;
  width: 0.625rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.625rem;
}

.default-theme {
  color: var(--background);
  background-color: var(--text-2);
  border-color: var(--text-2);
  composes: badge;
}

.muted {
  color: #fff;
  background-color: var(--text-3);
  border-color: var(--text-3);
  composes: badge;
}
.success-light {
  color: var(--confirm-1);
  background-color: var(--confirm-2);
  border-color: var(--confirm-2);
  composes: badge;
}
.warning {
  color: var(--accentYellow-text);
  background-color: var(--accentYellow);
  border-color: var(--accentYellow);
  composes: badge;
}
.success {
  color: #fff;
  background-color: var(--confirm-1);
  border-color: var(--confirm-1);
  composes: badge;
}
.error {
  color: var(--onSecondary);
  background-color: var(--error-1);
  border-color: var(--error-1);
  composes: badge;
}
